export const getSubRoute = (route, subRouteOf = '') => {
  if (route && typeof route === 'string') {
    return subRouteOf
      ? route.replace(subRouteOf, '').substring(1)
      : route.substring(1);
  } else {
    console.error(`Route ${route} of unsupported type ${typeof route}`);
    return route;
  }
};

/**
 *
 * @param {number} number to be fixed. can be a number or string
 * @param {number} to
 * @returns a string with decimal values fixed to the provide decimal place
 */

export const fixedDecimalValues = (number, to = 2) => {
  if (
    (number || number === 0) &&
    (typeof number === 'string' || typeof number === 'number')
  ) {
    if (number.toString().includes('.')) {
      return number
        .toString()
        .split('.')
        .map((num, i) => (i === 1 ? num.substring(0, to) : num))
        .join('.');
    }
    return number.toString();
  } else {
    console.error(`${number} of type ${typeof number} not supported`);
    return number;
  }
};

export const formatBytes = (a, b = 2, k = 1024) => {

	let d = Math.floor(Math.log(a) / Math.log(k));
	return 0 == a ? "0 Bytes" : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]

}
