import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: null,
    tempUser: null,
    loggedInUser: null,
    authToken: null,
    userId: null,
  },
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
    setTempUser: (state, action) => {
      state.tempUser = action.payload;
    },
    setLoggedInUser: (state, action) => {
      const tempObj = (({
        authToken,
        onboardingCompletion,
        userId,
        verified,
        role,
      } = {}) => ({
        authToken,
        onboardingCompletion,
        userId,
        verified,
        role,
      }))(action.payload);
      state.loggedInUser = tempObj;
      state.authToken = tempObj.authToken;
      state.userId = tempObj.userId;
    },
  },
});

export const { login, logout, setOtp, setTempUser, setLoggedInUser } =
  authSlice.actions;
export default authSlice.reducer;
