export const setLocalStorageItem = (key, value) => {
  try {
    const stringify = JSON.stringify(value);
    localStorage.setItem(key, stringify);
    return true;
  } catch (err) {
    console.error(`Error while setting localstorage ${err}`);
    return false;
  }
};

export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    console.error(`Error while removing localstorage ${err}`);
    return false;
  }
};

export const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);

  try {
    return JSON.parse(item);
  } catch (err) {
    console.error(`Error while removing localstorage ${err}`);
    return null;
  }
};
