export const allStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
export const nameRegExp = /^[A-Za-z ]+$/;

export const STRINGS = {
  login: "login",
  signup: "signup",
  otp: "otp",
  coach: "COACH",
  athlete: "ATHLETE",
  phoneAlreadyExist: "Phone Number Already Registered",
  emailAlreadyExist: "User with same email already exists",
  noUserFound: "No such user found.",
};

export const ERRORS_MSG = {
  invalidPhone: "Invalid Phone number",
  selectState: "Please Select state",
  selectDob: "Select your Date of birth",
  requiredFirstName: "firstname is required",
  requiredLastName: "lastname is required",
  requiredEmail: "Email is required",
  invalidEmail: "Email is invalid",
  requiredPassword: "Password is required",
  minLenghtPass: "Password must be at least 6 characters",
  regexPass:
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
  regexName:
    "Name Should not contain number, special charaters or blank spaces",

  reuiredField: "This field is required",
  requiredTncAcknowledgement: "T & C acknowledgement is required",
};

export const STORAGES = {
  token: "token",
  userId: "userId",
  onboardingCompletion: "onboardingCompletion",
  loginData: "loginData",
  username: "username",
  initRegisterdateTime: "initRegisterdateTime",
};
