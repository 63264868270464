import {
  setLocalStorageItem,
  removeLocalStorageItem,
  getLocalStorageItem,
} from "./localStorageHelper";
import { STORAGES, STRINGS } from "../constants/index";
import store from "../redux/store";
import axios from "axios";
import { applicationDetails, userProfile } from "../constants/api-config";
import {
  setApplicationDetails,
  setGlobalLoading,
  setUser,
} from "../redux/slices/globalSlice";
import { login, setLoggedInUser } from "../redux/slices/authSlice";
import ROUTES from "../constants/routes";
import appConfig from "../constants/appConfig";

export const setLocalStorageForLogin = ({
  token,
  userId,
  loginData,
  username,
}) => {
  setLocalStorageItem(STORAGES.token, token);
  setLocalStorageItem(STORAGES.userId, userId);
  setLocalStorageItem(STORAGES.loginData, loginData);
  setLocalStorageItem(STORAGES.username, username);
};

export const setLocalStorageForLogout = () => {
  removeLocalStorageItem(STORAGES.token);
  removeLocalStorageItem(STORAGES.userId);
  removeLocalStorageItem(STORAGES.loginData);
  removeLocalStorageItem(STORAGES.username);
  removeLocalStorageItem(STORAGES.initRegisterdateTime);
};

export const checkIfLogin = () => {
  return !!getLocalStorageItem(STORAGES.token);
};

export const getAppicationInfo = async () => {
  try {
    const applicationDetailsResponse = await axios.get(applicationDetails);
    if (applicationDetailsResponse.data.success) {
      return applicationDetailsResponse.data.data;
    } else {
      throw new Error("Unable to fetch appilication Data");
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserProfileData = async (token, userId) => {
  try {
    const userProfileResponse = await axios.get(userProfile, {
      headers: {
        "X-User-Id": userId,
        "X-Auth-Token": token,
      },
    });
    if (userProfileResponse.data.success) {
      return userProfileResponse.data.data;
    } else {
      throw new Error("Unable to fetch userProfile Data");
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUpdatedLoggedInUserDataAndUpdateLocalStorage = (
  prev,
  next = {}
) => {
  const nextKeys = Object.keys(next);
  const newObj = { ...prev };
  const nextCopy = { ...next };
  if (nextKeys.includes("verified")) {
    newObj.verified = nextCopy.verified;
    delete nextCopy.verified;
  }
  newObj.onboardingCompletion = { ...prev.onboardingCompletion, ...nextCopy };
  setLocalStorageItem(STORAGES.loginData, newObj);
  return newObj;
};

export const getRedirectLink = (loggedInUserData = null) => {
  const loggedInData = getLocalStorageItem(STORAGES.loginData);
  const { role, onboardingCompletion, verified } =
    loggedInUserData || loggedInData;
  const { welcomeVideoAcknowledged, initialOnboarding, planChoosen } =
    onboardingCompletion;

  if (!initialOnboarding) {
    if (!verified) {
      //navigate to OTP
      console.log("navigate to OTP");
      return { pathname: ROUTES.home, state: { authPage: STRINGS.otp } };
    } else if (!planChoosen && role === STRINGS.coach) {
      //buy plan here
      console.log("buying plan here");
      // navigate(`coach/subscription`, { replace: true });
      return { pathname: ROUTES.coachSubscription, state: null };
    } else if (!welcomeVideoAcknowledged) {
      //get the application details
      //navigate to onboarding video page
      console.log("navigate to onboarding video page");
      // navigate(ROUTES.onboardingVideo, { replace: true });
      return { pathname: ROUTES.onboardingVideo, state: null };
    } else {
      //doesn't decided yet what to do here
      console.log("inside `NOT DECIDED`");
    }
  } else {
    //navigate to dashboard
    console.log("navigate to dashboard getRedirectLink");
    return {
      pathname: roleHelper(role),
      state: null,
    };
  }
};
export const roleHelper = (role) => {
  return role === STRINGS.athlete
    ? ROUTES.athleteDashboard
    : ROUTES.coachDashboard;
};

export const checkLoginAndRehydrateStateOnAppLoad = async ({
  token: _token,
  userId: _userId,
  loginData: _loginData,
} = {}) => {
  const token = _token ? _token : getLocalStorageItem(STORAGES.token);
  const userId = _userId ? _userId : getLocalStorageItem(STORAGES.userId);
  const loginData = _loginData
    ? _loginData
    : getLocalStorageItem(STORAGES.loginData);
  const registrationStartTime = getLocalStorageItem(
    STORAGES.initRegisterdateTime
  );

  store.dispatch(setGlobalLoading(true));
  try {
    if (checkIfLogin()) {
      // check registration timeout

      if (
        registrationStartTime &&
        Date.now() - parseInt(registrationStartTime) >=
          appConfig.registrationTimeout &&
        !loginData.onboardingCompletion.initialOnboarding
      ) {
        setLocalStorageForLogout();
      } else {
        //updateLoginData
        store.dispatch(setLoggedInUser(loginData));
        store.dispatch(login(true));

        // fetch and update user details only for users whose registration is complete
        if (
          loginData.onboardingCompletion &&
          loginData.onboardingCompletion.initialOnboarding
        ) {
          // clear regustration start time from local storage if present
          registrationStartTime &&
            removeLocalStorageItem(STORAGES.initRegisterdateTime);

          const userProfileData = await getUserProfileData(token, userId);
          if (!userProfileData)
            throw new Error("Unable to fetch userProfile Data");
          store.dispatch(setUser(userProfileData));
        }
      }
    }

    // fetch and update application details
    const applicationDetails = await getAppicationInfo();
    if (!applicationDetails)
      throw new Error("Unable to fetch applicationDetails");
    store.dispatch(setApplicationDetails(applicationDetails));
  } catch (error) {
    setLocalStorageForLogout();
  } finally {
    store.dispatch(setGlobalLoading(false));
  }
};
