import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import Loader from "./components/loader";
import PrivateRoute from "./routes/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "./constants/routes";
import { checkLoginAndRehydrateStateOnAppLoad } from "./utility/authHelper";
import { getSubRoute } from "./utility/commonHelpers";
import { setError, setSuccess } from "./redux/slices/globalSlice";
import ContainerModal from "./components/ContainerModal";

const Auth = lazy(() => import("./pages/auth/Auth"));
const CouponDetailsWrapper = lazy(() => import("./pages/coupons/Coupon_Details"));
const PackageDetailsWrapper = lazy(() => import("./pages/packages/Package_Details"));
const SalesCouponDetailsWrapper = lazy(() =>
  import("./pages/salesCoupons/Sales_Agent_Coupon_Details")
);
const ReferralDetailsWrapper = lazy(() => import("./pages/referrals/Referral_Details"));
const Subscription = lazy(() => import("./pages/subscription"));
const OnboardingVideo = lazy(() => import("./pages/onboardingVideo"));
const NotFound = lazy(() => import("./pages/notFound"));
const DashboardRouter = lazy(() => import("./routes/router/DashboardRouter"));

const App = () => {
  const { error, success } = useSelector(({ global }) => global);
  const dispatch = useDispatch();

  useEffect(() => {
    checkLoginAndRehydrateStateOnAppLoad();
  }, [dispatch]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path={ROUTES.home} element={<Auth />} />
          <Route
            exact
            path="/coupons/:couponId"
            element={<CouponDetailsWrapper />}
          />
          <Route
            exact
            path="/salesCoupons/:couponId"
            element={<SalesCouponDetailsWrapper />}
          />
          <Route
            exact
            path="/referrals/:code"
            element={<ReferralDetailsWrapper />}
          />
          <Route
            exact
            path="/packages/:packageId"
            element={<PackageDetailsWrapper />}
          />

          <Route path="/*" element={<PrivateRoute />}>
            <Route
              path={getSubRoute(ROUTES.onboardingVideo)}
              element={<OnboardingVideo />}
            />
            <Route
              path={`${getSubRoute(ROUTES.dashboard)}/*`}
              element={<DashboardRouter />}
            />
            <Route
              exact
              path={getSubRoute(ROUTES.coachSubscription)}
              element={<Subscription />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
      {error && (
        <ContainerModal
          title="Error"
          text={error.errorText}
          handleClose={() => {
            dispatch(setError(null));
          }}
        />
      )}
      {success && (
        <ContainerModal
          title="Success"
          text={success.successText}
          handleClose={() => {
            dispatch(setSuccess(null));
          }}
        />
      )}
    </>
  );
};

export default App;
