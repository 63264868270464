import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const Box = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 5px ${(p) => p.theme.colors.gray.medium};
  background-color: white;
`;
export const Title = styled.h3`
  font-size: 2rem;
  color: ${(p) => p.theme.colors.red.primary};
`;
export const Text = styled.p`
  padding: 1rem;
  color: ${(p) => p.theme.colors.gray.dark};
`;
export const Btns = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
`;
