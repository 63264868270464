import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
  name: "global",
  initialState: {
    user: null,
    applicationDetails: null,
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setApplicationDetails: (state, action) => {
      state.applicationDetails = action.payload;
    },
    setGlobalLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      const { payload } = action;
      state.error = payload
        ? {
            errorText: payload.errorText,
          }
        : null;
    },
    setSuccess: (state, action) => {
      const { payload } = action;
      state.success = payload
        ? {
            successText: payload.successText,
          }
        : null;
    },
    updateUserCredit: (state, action) => {
      state.user = {
        ...state.user,
        userSpecificInformation: {
          ...state.userSpecificInformation,
          athleteCredits:
            state.user.userSpecificInformation.athleteCredits +
            Number(action.payload),
        },
      };
    },
  },
});

export const {
  setUser,
  setApplicationDetails,
  setGlobalLoading,
  setError,
  setSuccess,
  updateUserCredit,
} = globalSlice.actions;
export default globalSlice.reducer;
