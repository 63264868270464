import styled, { css, keyframes } from "styled-components";
import { colors } from "./theme";

export const SmallPara = styled.p`
  font-size: 0.8rem;
  font-weight: ${(p) => p.fw || "normal"};
  color: ${(p) => p.color || p.theme.colors.gray.dark};
`;
export const Para = styled.p`
  font-size: 1rem;
  font-weight: ${(p) => p.fw || "normal"};
  color: ${(p) => p.color || p.theme.colors.gray.dark};
`;
export const SmallHeading = styled.h6`
  font-size: 0.9rem;
  font-weight: ${(p) => p.fw || "bold"};
  color: ${(p) => p.color || p.theme.colors.gray.dark};
  text-align: ${(p) => (p.right ? "right" : "left")};
  margin: ${(p) => p.mt || 0} ${(p) => p.mr || 0} ${(p) => p.mb || 0}
    ${(p) => p.ml || 0};

  ${(p) => p.theme.devices.phone} {
    font-size: 1rem;
  }
`;
export const MediumHeading = styled.h4`
  font-size: 1.2rem;
  font-weight: ${(p) => p.fw || "bold"};
  color: ${(p) => p.color || p.theme.colors.gray.dark};
  text-align: ${(p) => (p.right ? "right" : "left")};
  margin: ${(p) => p.mt || 0} ${(p) => p.mr || 0} ${(p) => p.mb || 0}
    ${(p) => p.ml || 0};
`;
export const LargeHeading = styled.h2`
  font-size: 1.4rem;
  font-weight: ${(p) => p.fw || "bold"};
  color: ${(p) => p.color || p.theme.colors.gray.dark};
  margin: ${(p) => p.mt || 0} ${(p) => p.mr || 0} ${(p) => p.mb || 0}
    ${(p) => p.ml || 0};
`;

export const TextWithDisable = styled.p`
  color:${(p) =>
    p.disabled ? p.theme.colors.gray.medium : p.theme.colors.blue.primary};
    text-align: left;
  font-size: 0.9rem;
  cursor: ${p=>p.disabled ? "unset" : "pointer"};
`;

export const Btn = css`
  padding: 10px 25px;
  border-radius: 5px;
  border: 0;
  outline: 0;
  cursor: pointer;
`;

export const GradientBtn = styled.button`
  ${Btn};
  background: linear-gradient(
    90deg,
    ${(p) => p.theme.colors.red.primary},
    ${(p) => p.theme.colors.yellow.primary}
  );
  color: ${(p) => p.theme.colors.white};
  font-weight: bold;
  width: ${(p) => (p.full ? "100%" : "unset")};
  margin: ${(p) => p.mt || 0} ${(p) => p.mr || 0} ${(p) => p.mb || 0}
    ${(p) => p.ml || 0};
`;

export const GrayBtn = styled.button`
  ${Btn};
  background-color: ${(p) => p.bg || p.theme.colors.gray.light};
  color: ${(p) => p.color || p.theme.colors.black};
  margin: ${(p) => p.mt || 0} ${(p) => p.mr || 0} ${(p) => p.mb || 0}
    ${(p) => p.ml || 0};
  font-weight: bold;
`;

// flex area
export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexBetweenCenter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// keyframes
export const rotate = keyframes`
 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg);}
`;
