const DASHBOARD_ROUTES = '/dashboard';

const ROUTES = {
  home: '/',
  onboardingVideo: '/onboardingVideo',
  verify: '/verify',

  // dashboard routes
  dashboard: DASHBOARD_ROUTES,
  coachDashboard: `${DASHBOARD_ROUTES}/coach`,
  athleteDashboard: `${DASHBOARD_ROUTES}/athlete`,
  coachSubscription: '/coach/subscription',
};

export default ROUTES;
