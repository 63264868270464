import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import { getLocalStorageItem } from '../utility/localStorageHelper';
import ROUTES from '../constants/routes';
import { STORAGES } from '../constants';

const PrivateRoute = () => {
  const token = getLocalStorageItem(STORAGES.token);
  if (token) {
    return <Outlet />;
  } else {
    return <Navigate to={ROUTES.home} />;
  }
};

export default PrivateRoute;
