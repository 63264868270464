import React from 'react';
import { render } from 'react-dom';
import App from './App/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { lightMode } from './App/styled/theme';
import store from './App/redux/store';

render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={lightMode}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
