import styled, { keyframes } from "styled-components";
import { rotate } from "../../styled/mixins";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderDiv = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fcd100;
  border-bottom-color: transparent;
  border-top-color: transparent;
  animation: ${rotate} 0.5s infinite;
`;


