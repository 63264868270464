import React from "react";
import { LoaderDiv, Wrapper } from "./style";

const Loader = () => {
  return (
    <Wrapper>
      <LoaderDiv></LoaderDiv>
    </Wrapper>
  );
};

export default Loader;
