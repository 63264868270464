import React, { memo } from 'react';
import { GradientBtn, GrayBtn } from '../../styled/mixins';
import { Box, Btns, Container, Text, Title } from './style';

const ContainerModal = memo(
  ({ title, text, handleClose, type = 'Error', onConfirm }) => {
    return (
      <Container>
        <Box>
          <Title>{title}</Title>
          <Text>{text}</Text>
          <Btns>
            {type === 'confirm' && (
              <GradientBtn onClick={onConfirm}> Confirm</GradientBtn>
            )}
            <GrayBtn onClick={handleClose}>Close</GrayBtn>
          </Btns>
        </Box>
      </Container>
    );
  }
);

export default ContainerModal;
