/**
 * CONTAINS API KEYS, BASE URL used in http services
 */
const baseUrl = "https://app.coachable-app.com/api/v1/";
// const baseUrl = "https://staging.able-apps.com/api/v1/";
// const baseUrl = "http://localhost:3000/api/v1/";

const API_CONFIG = {
  signin: `${baseUrl}web-signin/`,
  signup: `${baseUrl}signup/`,
  disableUser: `${baseUrl}disable_user/`,
  signout: `${baseUrl}logout`,
  userProfile: `${baseUrl}user_profile`,
  applicationDetails: `${baseUrl}applicationDetails/`,
  verify: `${baseUrl}users/`, //users/:username/verify
  resendVerification: `${baseUrl}users/`, //users/:username/send_verification
  coachSubscriptions: `${baseUrl}users/coach/plans`,
  finalSignupAcknowledgement: `${baseUrl}users/submit_info`,
  updateSubscriptionPlanCardInfo: `${baseUrl}users/cards/collect_info`,
};

export const {
  signin,
  signup,
  userProfile,
  applicationDetails,
  verify,
  coachSubscriptions,
  finalSignupAcknowledgement,
  signout,
  updateSubscriptionPlanCardInfo,
  resendVerification,
  disableUser,
} = API_CONFIG;
