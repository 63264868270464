export const breakpoints = {
  smallPhone: 350,
  phone: 550,
  smallTablet: 768,
  tablet: 960,
  desktop: 1200,
  mediumDesktop: 1350,
  largeDesktop: 1500,
};

export const devices = {
  smallPhone: `@media screen and (min-width: ${breakpoints.smallPhone}px)`,
  phone: `@media screen and (min-width: ${breakpoints.phone}px)`,
  smallTablet: `@media screen and (min-width: ${breakpoints.smallTablet}px)`,
  tablet: `@media screen and (min-width: ${breakpoints.tablet}px)`,
  desktop: `@media screen and (min-width: ${breakpoints.desktop}px)`,
  mediumDesktop: `@media screen and (min-width: ${breakpoints.mediumDesktop}px)`,
  largeDesktop: `@media screen and (min-width: ${breakpoints.largeDesktop}px)`,
};

export const colors = {
  blue: {
    primary: '#2d70c2',
    dark: '#1d1c4f',
    light: '#90c2fe',
  },
  yellow: {
    primary: '#fcd100',
  },
  green: {
    primary: '#008790',
    light: '#4cc8d0',
  },
  red: {
    primary: '#db003c',
  },
  gray: {
    dark: '#4a4a4a',
    primary: '#606977',
    medium: '#b0bac7',
    light: '#d7dae0',
    pale: '#f3f6fb',
  },
  white: '#ffffff',
  black: '#000',
};

const shadows = {
  sm: `1px 1px 5px ${colors.gray.medium}`,
};

const common = {
  devices,
  shadows,
};

export const lightMode = {
  ...common,
  colors,
};
